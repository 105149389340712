<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" @keyup.enter.native="submitForm('ruleForm')">
    <div class="login-box" >
      <div class="login-tabs-box">
        <el-tabs>
          <el-tab-pane label="重置您的密码"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="tip-error" >
        <label v-show="errorText">{{ errorText }}</label>
      </div>
      <div>
        <el-form-item class="login-input" prop="mobile">
          <el-input v-model="ruleForm.mobile" :disabled="step!=='verify'" :clearable="false" size="medium" placeholder="请输入手机号">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
        </el-form-item>
        <div v-if="step==='verify'">
          <el-form-item class="login-input" prop="imgCode">
            <el-input ref="imgCode" class="image-code-input" :maxlength="4" :minlength="4" v-model="ruleForm.imgCode" size="medium" :clearable="false" placeholder="请输入图文验证码">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              <div v-if="imgUrl" class="image-code-box" slot="suffix" @click="createImageCode">
                <img :src="imgUrl"/>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item class="login-input" prop="code">
            <el-input v-model="ruleForm.code" type="password" size="medium" :clearable="false" placeholder="请输入短信验证码" >
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
              <el-link v-show="showSendSmsCode" class="login-input-suffix" slot="suffix" type="primary" :underline="false" @click="sendSmsCode">
                {{ sendSmsText }}
              </el-link>
            </el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item class="login-input" prop="password">
            <el-input ref="password" v-model="ruleForm.password" size="medium" placeholder="请输入新的密码" show-password>
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
        </div>
      </div>
      <el-form-item>
        <el-button type="primary" size="medium" class="submit-login" :loading="loading" @click="submitForm('ruleForm')">
          {{ step === 'verify' ? '下一步' : '修改密码' }}
        </el-button>
      </el-form-item>
      <div class="forget-pwd">
        <label>已有账号，</label>
        <router-link to="/login">
          <el-link type="primary" :underline="false" class="forget-pwd-link">登录</el-link>
        </router-link>
      </div>
    </div>
  </el-form>
</template>

<script>
import { getCookies } from '@/utils/cookie'
import { createSession, getImgCode, sendSms, forgetChangePassword, verifySms } from '@/api/user'

const keyMoblie = 'mobile'
export default {
  data() {
    return {
      loading: false,
      sessionId: '',
      timer: null,
      errorText: '',
      sendSmsText: '发送验证码',
      step: 'verify',
      ruleForm: {
        mobile: '',
        password: '',
        code: '',
        imgCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'none' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'none' }
        ],
        imgCode: [
          { required: true, message: '请输入图片验证码', trigger: 'none' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'none' }
        ]
      },
      redirect: undefined,
      otherQuery: null,
      imgUrl: ''
    }
  },
  watch: {
    $route: {
      handler(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  computed: {
    showSendSmsCode() {
      return this.ruleForm.imgCode.length === 4
    }
  },
  created() {
    const { mobile } = getCookies(keyMoblie) || { }
    this.ruleForm.mobile = mobile || ''
    this.createdSession()
    this.ruleForm.imgCode = ''
    this.$nextTick(() => {
      this.$refs.imgCode.focus()
    })
  },
  mounted() {
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    async createdSession() {
      const { id } = await createSession()
      this.sessionId = id
      getImgCode({ id }).then(src => {
        this.imgUrl = src
      })
    },
    async createImageCode() {
      const src = await getImgCode({ id: this.sessionId })
      this.imgUrl = src
    },
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.loading = true
          let res
          if (this.step === 'verify') {
            res = await this.verifyBySms()
          } else {
            res = await this.restPassWd()
          }
          this.loading = false
          const { code, message } = res
          if (code && this.step === 'verify') {
            this.step = 'restPwd'
            return false
          }
          if (code) {
            this.$router.push({ path: `/login?redirect=${this.redirect || '/'}`, query: this.otherQuery })
            return
          }
          this.errorText = message
        } else {
          return false
        }
      })
    },
    async restPassWd() {
      try {
        const { mobile, code, password } = this.ruleForm
        const ret = await forgetChangePassword({ mobile, newPassword: password, code, smsSessionId: this.sessionId })
        return { code: true, ...ret }
      } catch(ex) {
        return { code: false, message: ex }
      }
    },
    async verifyBySms() {
      try {
        const { mobile, code } = this.ruleForm
        const ret = await verifySms({ mobile, code, smsSessionId: this.sessionId })
        return { code: true, ...ret }
      } catch(ex) {
        return { code: false, message: ex }
      }
    },
    async sendSmsCode() {
      if (this.sendSmsText !== '发送验证码') return
      const params = {
        sessionId: this.sessionId,
        mobile: this.ruleForm.mobile,
        imageCode: this.ruleForm.imgCode,
        captchaType: 'ForgotPassword',
      }
      const res = await sendSms(params)
      const { status, message } = res
      switch (status) {
        case 1:
          this.timerHandler()
          break
        case 2:
          await this.createImageCode()
          break
        case 3:
          await this.createdSession()
          break
        default: break
      }
      this.errorText = message
    },
    timerHandler() {
      let time = 59
      this.timer = setInterval(() => {
        this.sendSmsText = `${time--}s`
        if (time === 0 && this.timer) {
          clearInterval(this.timer)
          this.sendSmsText = '发送验证码'
        }
      }, 1000)
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  font-weight: 100;
  font-size: 14px;
}
.tip-error {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
  text-align: left;
  color: red;
  label {
    font-weight: normal;
  }
}
.login-box {
  background: #fff;
  width: 30vw;
  width: 400px;
  height: 388px;
  padding: 24px;
  box-shadow: 1px 1px 1px 1px #ededed;
  border-radius: 5px;
  .login-tabs-box {
    font-size: 23px;
    line-height: 60px;
    &>div {
      cursor: pointer;
    }

  }
}
.forget-pwd {
  line-height: 20px;
  text-align: right;
}
.image-code-box {
  width: 100px;
  height: 34px;
  overflow: hidden;
  padding: 1px 0px;
  margin-right: -4px;
  cursor: pointer;
  img {
    height: inherit;
    width: inherit;
  }
}
.image-code-input input.el-input__inner {
  padding-right: 100px;
}
.active_tab {
  color: #1890ff;
}
.login-input-suffix {
  line-height: 36px;
  margin-right: 5px;
}
.submit-login {
  width: 100%;
}
</style>
